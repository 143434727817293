import {
    ADMIN_URL,
    DATA_URL,
    DEV_ADMIN_URL,
    DEV_DATA_URL,
    DEV_INTEGRATION_URL,
    DEV_WEBSITES_URL,
    INTEGRATION_URL,
    PRO_ADMIN_URL,
    PRO_DATA_URL,
    PRO_INTEGRATION_URL,
    PRO_WEBSITES_URL,
    STAGING_ADMIN_URL,
    STAGING_DATA_URL,
    STAGING_INTEGRATION_URL,
    STAGING_WEBSITES_URL,
    TESTING_ADMIN_URL,
    TESTING_DATA_URL,
    TESTING_INTEGRATION_URL,
    TESTING_WEBSITES_URL,
    WEBSITES_URL,
} from '../requests/urls';
import { hexTransparencies } from './Lookup';

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '');

// ** Checks if the passed date is today
const isToday = date => {
    const today = new Date();
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = value => {
    try {
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York';
        console.log('🚀 ~ userTimeZone:', userTimeZone);
        if (!userTimeZone) return value;
        if (!value) return null;

        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: userTimeZone,
        };

        return new Intl.DateTimeFormat('en-US', options).format(new Date(value));
    } catch (e) {
        console.log('[catch formatDate] =>', e);
        return value;
    }
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value);
    let formatting = { month: 'short', day: 'numeric' };

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' };
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData');
export const getUserData = () => JSON.parse(localStorage.getItem('userData'));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
    if (userRole === 'admin') return '/';
    if (userRole === 'client') return '/access-control';
    return '/login';
};

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#7367f01a', // for option hover bg-color
        primary: '#7367f0', // for selected option bg-color
        neutral10: '#7367f0', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed', // for input hover border-color
    },
});
//get random number between to number
export const randomIntFromInterval = (min, max) => {
    // min and max included
    //(Math.random() * (max - min) + min).toFixed(2)
    return Math.floor(Math.random() * (max - min + 1) + min);
};
//set cookie
export function setCookie(name, value, time) {
    let expires = '';
    if (time) {
        const date = new Date();
        date.setTime(date.getTime() + time * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${value}${expires}; path=/`;
}
//get cookie
export function getCookie(name) {
    const nameEQ = `${name}=`;
    //console.log('cookie', document.cookie)
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
//remove cookie
export function eraseCookie(name) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function prettify(str) {
    return str.replace(/(-|^)([^-]?)/g, function (_, prep, letter) {
        return (prep && ' ') + letter.toUpperCase();
    });
}
//check url for request
export function checkUrl(type) {
    //console.log('my function', window.location.hostname, type)
    // process.env._ENVIRONMENT
    if (type === 'admin') {
        // if (process.env._ENVIRONMENT) {
        //     return `https://admin-${process.env._ENVIRONMENT}-dot-multihub-repli.uc.r.appspot.com`;

        // } else {
        //     return ADMIN_URL
        // }
        if (window.location.hostname.includes('console.multihub.io')) {
            return PRO_ADMIN_URL;
        } else if (window.location.hostname.includes('staging')) {
            return STAGING_ADMIN_URL;
        } else if (window.location.hostname.includes('dev')) {
            return DEV_ADMIN_URL;
        } else if (window.location.hostname.includes('testing')) {
            return TESTING_ADMIN_URL;
        } else {
            return ADMIN_URL;
        }
    } else if (type === 'integration') {
        // if (process.env._ENVIRONMENT) {
        //     return `https://integrations-${process.env._ENVIRONMENT}-dot-multihub-repli.uc.r.appspot.com`;

        // } else {
        //     return INTEGRATION_URL
        // }
        if (window.location.hostname.includes('console.multihub.io')) {
            return PRO_INTEGRATION_URL;
        } else if (window.location.hostname.includes('staging')) {
            return STAGING_INTEGRATION_URL;
        } else if (window.location.hostname.includes('dev')) {
            return DEV_INTEGRATION_URL;
        } else if (window.location.hostname.includes('testing')) {
            return TESTING_INTEGRATION_URL;
        } else {
            return INTEGRATION_URL;
        }
    } else if (type === 'data') {
        // if (process.env._ENVIRONMENT) {
        //     return `https://integrations-${process.env._ENVIRONMENT}-dot-multihub-repli.uc.r.appspot.com`;

        // } else {
        //     return INTEGRATION_URL
        // }
        if (window.location.hostname.includes('console.multihub.io')) {
            return PRO_DATA_URL;
        } else if (window.location.hostname.includes('staging')) {
            return STAGING_DATA_URL;
        } else if (window.location.hostname.includes('dev')) {
            return DEV_DATA_URL;
        } else if (window.location.hostname.includes('testing')) {
            return TESTING_DATA_URL;
        } else {
            return DATA_URL;
        }
    } else if (type === 'websites') {
        // if (process.env._ENVIRONMENT) {
        //     return `https://websites-${process.env._ENVIRONMENT}-dot-multihub-repli.uc.r.appspot.com`;

        // } else {
        //     return WEBSITES_URL
        // }
        if (window.location.hostname.includes('console.multihub.io')) {
            return PRO_WEBSITES_URL;
        } else if (window.location.hostname.includes('staging')) {
            return STAGING_WEBSITES_URL;
        } else if (window.location.hostname.includes('dev')) {
            return DEV_WEBSITES_URL;
        } else if (window.location.hostname.includes('testing')) {
            return TESTING_WEBSITES_URL;
        } else {
            return WEBSITES_URL;
        }
    }
}
//this function is being used with bugsnag
export const appType = () => {
    if (window.location.hostname.includes('console.multihub.io')) {
        return 'production';
    } else if (window.location.hostname.includes('staging')) {
        return 'staging';
    } else if (window.location.hostname.includes('dev')) {
        return 'dev';
    } else if (window.location.hostname.includes('testing')) {
        return 'testing';
    } else {
        return 'local';
    }
};

export const setLocalStorage = (key, value) => {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Could not save ${key} to localStorage`);
    }
};

export const getLocalStorage = key => {
    try {
        const result = window.localStorage.getItem(key);
        return JSON.parse(result);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Could not save ${key} to localStorage`);
    }
};

export const formatPhoneNumberUS = e => {
    const input = e;
    let formattedPhoneNumber = input;

    // Remove all non-digit characters
    const digitsOnly = input.replace(/\D/g, '');

    if (digitsOnly.length > 0) {
        formattedPhoneNumber = '(' + digitsOnly.slice(0, 3) + ') ';

        if (digitsOnly.length > 3) {
            formattedPhoneNumber += digitsOnly.slice(3, 6) + '-';

            if (digitsOnly.length > 6) {
                formattedPhoneNumber += digitsOnly.slice(6, 10);
            }
        }
    }
    return formattedPhoneNumber;
};

export const checkFormatPhoneNumberUS = e => {
    if (!e) return;
    const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const phone = e;
    let formattedPhoneNumber = '';
    if (!phoneNumberRegex.test(phone)) {
        formattedPhoneNumber = phone.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
    } else {
        formattedPhoneNumber = phone;
    }

    return formattedPhoneNumber;
};

export const getObjectKeyByValue = (obj, value) => {
    return Object.keys(obj).find(key => obj[key] === value);
};

export const addRgbOpacity = (rgba, alpha) => {
    rgba = rgba.replace(/\s/g, '').toLowerCase();
    if (alpha === '') return rgba;
    alpha = Number(alpha);
    if (alpha < 0 || alpha > 100 || isNaN(alpha)) {
        throw new Error('Invalid alpha');
    }
    if (!rgba.startsWith('rgba') || rgba.split(',').length - 1 !== 3) {
        throw new Error('Not rgba format');
    }
    // matches any number of digits with a single decimal point followed by ")"
    // i.e. alpha value in rgba format "rgba(0, 0, 0, <alpha>)"
    const regex = /(\d+(\.\d+)?)(?=\))/;
    const opacity = rgba.match(regex)[0];
    const computed = (Number(opacity) * (alpha / 100)).toFixed(2);
    return rgba.replace(regex, computed).replace(/\s/g, '');
};

export const hexValidator = hex => {
    // must start with #, followed by AN pattern 3/6/8 characters long
    const hexRegex = /^#([0-9a-f]{3}|[0-9a-f]{6}|[0-9a-f]{8})$/i;
    return hexRegex.test(hex);
};

export const addHexOpacity = (hex, opacity) => {
    if (!hexValidator(hex)) {
        throw new Error('Invalid hex format');
    }
    if (opacity < 0 || opacity > 100 || typeof opacity === 'string') {
        throw new Error('Invalid opacity');
    }
    if (hex.length === 7) {
        return !opacity ? hex + 'FF' : hex + hexTransparencies[opacity];
    } else if (hex.length === 9) {
        const existingAlpha = hex.slice(7);
        const multiplier = getObjectKeyByValue(hexTransparencies, existingAlpha);
        const newMultiplier = Math.floor(multiplier * (opacity / 100));
        const newAlpha = hexTransparencies[newMultiplier] || hexTransparencies[opacity];
        return hex.slice(0, 7) + newAlpha;
    } else if (hex.length === 4) {
        const hexSplit = hex.slice(1).split('');
        const hexExpanded = hexSplit.map(char => char + char).join('');
        return `#${hexExpanded}${hexTransparencies[opacity]}`;
    }
};

export const domainValidator = inputDomain => {
    const domainPattern = /^(?!www\.)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,2}$/;
    return domainPattern.test(inputDomain);
};

export function CapitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function ParseQueryString(queryString) {
    const keyValuePairs = queryString.split('&');
    const result = {};

    keyValuePairs.forEach(pair => {
        const [key, value] = pair.split('=');
        if (key && value) {
            result[key] = decodeURIComponent(value);
        }
    });

    return result;
}

export function getInitials({ name = '', limit = 2 }) {
    const words = name.split(' ');
    const initials = words
        .map(word => word.charAt(0))
        .join('')
        .toUpperCase()
        .slice(0, limit);
    return initials;
}